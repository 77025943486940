import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas"
import { Link, StaticQuery, graphql } from "gatsby"
import React, { useEffect, useState } from "react"
import SVGIcon from "../components/common/SVGIcon"
import Frame from "../components/common/frame"
import "../styles/pages/happy-holiday.scss"

const DavesHoliday = () => {
  const [riveLoad, setRiveLoad] = useState(false)
  const { rive, RiveComponent } = useRive({
    src: "https://media.graphassets.com/zzv5QBNhTcuWSSTz17Vw",
    stateMachines: "Holiday Game",
    autoplay: true,
    automaticallyHandleEvents: true,
    onLoad: () => {
      setRiveLoad(true)
    },
    layout: new Layout({
      fit: Fit.Fill,
      alignment: Alignment.TopCenter,
    }),
  })

  useEffect(() => {
    if(riveLoad){
      const DsktopRive = document.querySelector(".desktop-riv")
      const RiveBoard = document.querySelector(".rive-board")
      if(window.innerWidth * 0.62 < window.innerHeight){
        DsktopRive.style.height = "100vh"
        RiveBoard.classList.add("position-absolute")
      }
    }
  }, [riveLoad])

  const [player, setPlayer] = useState()
  useEffect(() => {
    const song = "https://media.graphassets.com/Kqlp6XET2W5kVEX0FGZw"
    let audioTag = new Audio()
    audioTag.addEventListener(
      "load",
      () => {
        audioTag.play()
      },
      true
    )
    audioTag.src = song
    audioTag.autoplay = true
    audioTag.loop = true
    setPlayer(audioTag)
  }, [])

  const PlayPause = () => {
    if (player.paused) {
      player.play()
    } else {
      player.pause()
    }
  }

  const onRiveEventReceived = riveEvent => {
    const eventData = riveEvent.data
    if (eventData[0] === "Copy Click") {
      navigator.clipboard.writeText(window.location.href)
    }
  }

  useEffect(() => {
    if (rive) {
      rive.on("statechange", onRiveEventReceived)
    }
  }, [rive])

  return (
    <>
      <StaticQuery
        query={graphql`
          query DavesHoliday {
            SuperOps {
              pages(where: { title: "Daves holiday" }) {
                title
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo } = page
              return (
                <div className="happy-holidays position-relative">
                  <Frame
                    IsFooterVisble="No"
                    bodyClassName="dave-holiday-body"
                    seo={seo}
                  >
                    <div className="mobile-notice text-center">
                      <SVGIcon
                        name="happyHoliday2024"
                        className="holiday-logo mt50 down-to-up-1"
                      />
                      <p className="notice-text font-roboto mx-auto p18 mt60 down-to-up-2">
                        For the best experience, we recommend playing the game
                        on a desktop or tablet.
                      </p>
                      <div className="d-flex align-items-center justify-content-center gap-8 mt80 down-to-up-3">
                        <p className="font-roboto fw-600 m-0">Made with love</p>
                        <Link to="/">
                          <SVGIcon name="logo-dark" className="sps-logo" />
                        </Link>
                      </div>
                    </div>

                    <div className="desktop-riv position-relative">
                      <RiveComponent
                        className="rive-board"
                      />
                      <div
                        className={`audio-control position-absolute d-flex align-items-center ${
                          riveLoad ? "show" : ""
                        }`}
                        onClick={() => PlayPause()}
                      >
                        <SVGIcon name="playPause" className="play-pause-btn" />
                        <p className="text">sound</p>
                      </div>
                    </div>
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default DavesHoliday
